<template>
  <tr>
    <td
      class="px-4"
      :style="canViewOriginalPrice ? 'width: 20%' : 'width: 30%'"
    >
      <span style="font-size: 11px">{{ productItem.barCode }}</span>
      <br />
      <span style="font-size: 12px">({{ productItem.productCode }})</span>
      <br />
      <span>{{ productItem.name }}</span>
    </td>
    <td class="px-4 text-right" style="width: 5%">
      <span>{{ productItem.quantityApprove }}</span>
    </td>
    <td class="px-4" style="width: 19%">
      <div v-if="editState">
        <b-form-textarea
          :disabled="productItem.productType !== 2"
          style="border-color: #007bff"
          size="sm"
          v-model="productItem.IMEI"
          :placeholder="'IMEI'"
          @keyup="enterClicked()"
          @change="checkValidation"
          class="w-100 h-90"
        ></b-form-textarea>
      </div>
      <span v-else>{{ productItem.IMEI }}</span>
    </td>
    <td class="px-4 text-right" style="width: 14%">
      <div v-if="editState">
        <b-form-input
          :disabled="productItem.productType === productType.PRODUCT_IMEI"
          style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
          v-model="productItem.quantity"
          type="number"
          :min="0"
          placeholder="Số lượng"
          class="w-100 h-90 text-right"
          @change="checkValidation"
        ></b-form-input>
      </div>
      <span v-else>{{ productItem.quantity }}</span>
    </td>
    <td
      class="px-4 text-right"
      style="width: 20%"
      v-show="canViewOriginalPrice"
    >
      <div v-if="editState">
        <b-form-input
          size="sm"
          style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
          v-model="productItem.price"
          placeholder="Đơn giá"
          :min="1"
          class="w-100 h-90 text-right"
          @change="checkValidation"
          v-mask="mask"
          :disabled="true"
        ></b-form-input>
        <p style="color: red" v-if="error">Hãy nhập đầy đủ thông tin</p>
      </div>
      <span v-else>{{ formatMoney(productItem.price) }}</span>
    </td>

    <td
      class="px-4 text-right"
      style="width: 15%"
      v-show="canViewOriginalPrice"
    >
      <span>{{ formatMoney(totalPriceOfProduct) }}</span>
    </td>
  </tr>
</template>

<style>
.input {
  border: 1px solid #ecf0f3;
  border-radius: 5px;
  padding-left: 5px;
}

*:focus {
  outline: none;
}
</style>

<script>
import { currencyMask, unMaskPrice } from './../../utils/common';
import { PRODUCT_TYPE } from './../../utils/enum';

export default {
  props: [
    'productItem',
    'index',
    'status',
    'editState',
    'canViewOriginalPrice',
  ],
  data() {
    return {
      edit: true,
      error: false,
      mask: currencyMask,
      productType: PRODUCT_TYPE,
    };
  },
  computed: {
    totalPriceOfProduct() {
      if (this.productItem.name != '') {
        return this.productItem.quantity * unMaskPrice(this.productItem.price);
      } else {
        return this.productItem.totalPrice;
      }
    },
  },
  created() {},
  updated() {
    this.checkAddRow();
  },
  methods: {
    checkValidation() {
      this.error = false;
      this.edit = true;
      let dataResponse = this.productItem;
      dataResponse.price = unMaskPrice(this.productItem.price);
      this.$emit('update', dataResponse);
    },
    checkAddRow() {
      this.edit = true;
    },
    enterClicked() {
      let countLine = 0;
      let tmp = this.productItem.IMEI.split(/\r\n|\r|\n/);
      tmp.forEach((element) => {
        if (element.trim() !== '') {
          countLine++;
        }
      });
      this.productItem.quantity = countLine;
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
  },
};
</script>
